(function ($) {
 
    $.fn.animatedModal = function(options) {
        var modalLinks = $(this);
        var clickedLink;
        var $body = $('body');

        //Defaults
        var settings = $.extend({
            modalTarget:'animatedModal', 
            position:'fixed',
            top:'0',
            left:'0',
            bottom:'0',
            right:'0',
            zIndexIn: '9999',  
            zIndexOut: '-9999',  
            color: '#000000',
            opacityIn:'1',  
            opacityOut:'0', 
            animatedIn:'zoomIn',
            animatedOut:'zoomOut',
            animationDuration:'.6s', 
            overflow:'hidden',
            // Callbacks
            beforeOpen: function() {},           
            afterOpen: function() {}, 
            beforeClose: function() {}, 
            afterClose: function() {}
        }, options);
        
        var closeBt = $('.close-'+settings.modalTarget);

        var href = $(modalLinks).attr('href'),
            id = $body.find('#'+settings.modalTarget),
            idConc = '#'+id.attr('id');
            // Default Classes
            id.addClass('animated');
            id.addClass(settings.modalTarget+'-off');

        //Init styles
        var initStyles = {
            'position':settings.position,
            'top':settings.top,
            'left':settings.left,
            'bottom':settings.bottom,
            'right':settings.right,
            'background-color':settings.color,
            '-webkit-overflow-scrolling': 'touch',
            'overflow': settings.overflow,
            'overflow-y': navigator.userAgent.match(/(iPod|iPhone|iPad)/) ? 'scroll' : 'hidden',
            'z-index':settings.zIndexOut,
            'opacity':settings.opacityOut,
            '-webkit-animation-duration':settings.animationDuration,
            '-moz-animation-duration':settings.animationDuration,
            '-ms-animation-duration':settings.animationDuration,
            'animation-duration':settings.animationDuration
        };

        // API
        if (options === 'close') {
            closeModal();

            return;
        }

        //Apply stles
        id.css(initStyles);

        modalLinks.on('click', function(event) {
            event.preventDefault();
            clickedLink = $(this);

            openModal();
        });

        closeBt.on('click', function(event) {
            event.preventDefault();

            $body.css('overflow', 'auto');

            closeModal();
        });

        function openModal() {
            if (href == idConc) {
                if (id.hasClass(settings.modalTarget+'-off')) {
                    id.removeClass(settings.animatedOut);
                    id.removeClass(settings.modalTarget+'-off');
                    id.addClass(settings.modalTarget+'-on');
                }

                if (id.hasClass(settings.modalTarget+'-on')) {
                    settings.beforeOpen(clickedLink, id);
                    $body.css('overflow', 'hidden');
                    id.css({'opacity':settings.opacityIn,'z-index':settings.zIndexIn});
                    id.addClass(settings.animatedIn);
                    id.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', afterOpen);
                }
            }
        }

        function closeModal() {
            settings.beforeClose(clickedLink, id);

            if (id.hasClass(settings.modalTarget+'-on')) {
                id.removeClass(settings.modalTarget+'-on');
                id.addClass(settings.modalTarget+'-off');
            }

            if (id.hasClass(settings.modalTarget+'-off')) {
                id.removeClass(settings.animatedIn);
                id.addClass(settings.animatedOut);
                id.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', afterClose);
            }
        }

        function afterClose () {       
            id.css({'z-index':settings.zIndexOut});
            settings.afterClose(clickedLink, id); //afterClose
        }

        function afterOpen () {       
            settings.afterOpen(clickedLink, id); //afterOpen
        }

    }; // End animatedModal.js

}(jQuery));



        
