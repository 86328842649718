/*
 * Application
 */

$(function () {
    'use strict';

    var $window = $(window);
    var windowHeight = $window.height();
    var tooltipsActivated = false;
    var animatedModalActivated = false;
    var slickActivated = false;
    var sideMenuActivated = false;

    activateScrollChecker();

    if (window.history && window.history.pushState) {

        $(window).on('popstate', function() {
            var hashLocation = location.hash;
            var hashSplit = hashLocation.split("#!/");
            var hashName = hashSplit[1];

            if (hashName !== '') {
                var hash = window.location.hash;
                if (hash === '') {
                    parent.closeModal();
                }
            }
        });
    }

    $window.on('resize', function () {
        setTimeout(function () {
            if (!tooltipsActivated) {
                activateTooltips();
                tooltipsActivated = true;
            }
            if (!animatedModalActivated) {
                activateAnimatedModal();
                animatedModalActivated = true;
            }
            if (!slickActivated) {
                activateSlick();
                slickActivated = true;
            }
            if (!sideMenuActivated) {
                activateSideMenu();
                sideMenuActivated = true;
            }
        }, 0);

        setTimeout(function () {
            $('body').addClass('loaded');
        }, 2000);
    });

    $window.trigger('resize');

    window.closeModal = function () {
        $('.close-animatedModal').click();
    };

    function activateAnimatedModal() {
        if (self!=top) {
            $('.article-close').click(
                function (e) {
                    e.preventDefault();
                    parent.closeModal();
                    window.history.pushState({},"", '/');
                }
            );
        }

        activateAnimatedModalLinks($('#lecturer-events'));
    }

    function activateScrollChecker() {
        var $document = $(document),
            $element = $('#mainNav');

        $element.toggleClass('scrolled', $document.scrollTop() >= 60);

        $document.on('scroll', function () {
            $element.toggleClass('scrolled', $document.scrollTop() >= 60);
        });
    }

    function activateSideMenu() {
        $('.side-menu-toggle, .side-menu-close').on('click', function (event) {
            event.preventDefault();
            if ($(this).hasClass('side-menu-close')) {
                $('.slick-header').slick('slickPlay');
            } else {
                $('.slick-header').slick('slickPause');
            }

            $('.side-menu').toggleClass('active');
            $('#page-top').toggleClass('side-menu-open');
            $('body').toggleClass('stop-scroll');

            $('.side-menu-inner-block-content').removeAttr('style');
        });

        $('.side-menu-back').on('click', function (event) {
            event.preventDefault();

            $('.side-menu-inner-content').toggleClass('active');
            $('.side-menu-inner-block').addClass('hidden');
            $('.side-menu-inner-block-content').removeAttr('style');
        });

        $('.side-menu-link').on('click', function (event) {
            event.preventDefault();

            $('.' + $(this).data('block')).removeClass('hidden');
            $('.side-menu-inner-content').toggleClass('active');

            var sideMenuHeaderHeight = $('.side-menu-inner-block-header').outerHeight();
            var sideMenuContentHeight = windowHeight - sideMenuHeaderHeight;
            var sideMenuWidth = $('.side-menu-header').width();

            $('.side-menu-inner-block-content').css({
                'height': (sideMenuContentHeight - 110) + 'px',
                'width': sideMenuWidth + 'px'
            });
        });
    }

    function activateTooltips() {
        $(document).tooltip({
            selector: '[data-toggle=tooltip]'
        });
    }

    function activateSlick() {
        responsiveSliders();

        $('.slick-header').slick({
            dots: false,
            infinite: true,
            speed: 500,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 5000,
            prevArrow: $('.slick-arrows-header > .slick-prev'),
            nextArrow: $('.slick-arrows-header > .slick-next')
        });

        $('.slick-event-slides').slick({
            dots: true,
            infinite: true,
            speed: 500,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 5000,
            prevArrow: $('.slick-arrows-event-slides > .slick-prev'),
            nextArrow: $('.slick-arrows-event-slides > .slick-next')
        });
    }

    function responsiveSliders() {
        window.lastPosition = '';

        var slickOptions = {
            lecturers: {
                dots: true,
                arrows: true,
                infinite: true,
                rows: 1,
                autoplay: true,
                autoplaySpeed: 5000,
                slidesPerRow: 3,
                slidesToShow: 3,
                slidesToScroll: 3
            },
            articles: {
                dots: true,
                arrows: true,
                infinite: true,
                rows: 2,
                autoplay: true,
                autoplaySpeed: 5000,
                slidesPerRow: 3,
                slidesToShow: 1,
                slidesToScroll: 1
            },
            events: {
                dots: true,
                arrows: true,
                infinite: true,
                rows: 2,
                slidesPerRow: 3,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        };

        addSliders();

        function addResponsiveSlider($element, options, arrows) {
            if ($element.length > 0) {
                options.prevArrow = arrows.prev;
                options.nextArrow = arrows.next;

                $element.slick(options);

                activateAnimatedModalLinks($element);
            }
        }

        function addSliders() {
            var lecturerArrows = {
                prev: $('.slick-arrows-lecturers > .slick-prev'),
                next: $('.slick-arrows-lecturers > .slick-next')
            };

            var articlesArrows = {
                prev: $('.slick-arrows-articles > .slick-prev'),
                next: $('.slick-arrows-articles > .slick-next')
            };

            var relatedArticlesArrows = {
                prev: $('.slick-arrows-related-articles > .slick-prev'),
                next: $('.slick-arrows-related-articles > .slick-next')
            };

            var eventArrows = {
                prev: $('.slick-arrows-events > .slick-prev'),
                next: $('.slick-arrows-events > .slick-next')
            };

            addResponsiveSlider($('.slick-events'), slickOptions['events'], eventArrows);
            addResponsiveSlider($('.slick-lecturers'), slickOptions['lecturers'], lecturerArrows);
            addResponsiveSlider($('.slick-articles'), slickOptions['articles'], articlesArrows);
            addResponsiveSlider($('.slick-related-articles'), slickOptions['lecturers'], relatedArticlesArrows);
        }
    }

    function activateAnimatedModalLinks($element) {
        $element.find('div.open-animatedModal, a.open-animatedModal').animatedModal({
            beforeOpen: function ($link, $modal) {
                if($('#animatedModalContent').length == 0) {
                    window.history.pushState({}, "", $link.data('src'));
                    $('<iframe></iframe>')
                        .appendTo($modal)
                        .attr('id', 'animatedModalContent')
                        .attr('src', $link.data('src'))
                        .css('position', 'static')
                        .on('load', function () {
                            $modal.find('.overlay-animatedModal').fadeOut();
                        });
                    $('body').addClass('no-scroll');
                    $('#animatedModal').removeClass('hidden');
                }
                console.log('testas');
            },
            afterClose: function ($link, $modal) {
                $modal.addClass('hidden');
                $modal.find('#animatedModalContent').remove();
                $modal.find('.overlay-animatedModal').show();
                window.history.pushState({},"", "/");
                $('body').removeClass('no-scroll');
                console.log('close');
            }
        });
    }

    $('.buy-link').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var $button = $(this);
        var $modal = $('#modalBuy');
        var $title = $modal.find('.modal-title');
        $title.find('.price').html($button.data('price') + '&euro;');
        $title.find('.name').text($button.data('name'));
        $title.find('.sub-name').text($button.data('sub-name'));
        $title.find('.date').text($button.data('date'));
        $title.find('.city').text($button.data('city'));

        $modal.find('#event').val($button.data('event'));

        $modal.modal('show');
    });

    $('#invoice').change(function () {
        var $check = $(this);

        if ($check.is(':checked')) {
            $check.parents().eq(4).find('.form-group.invoice').css('opacity', 1);
        } else {
            $check.parents().eq(4).find('.form-group.invoice').css('opacity', 0.2);
        }
    });

    $("#mainNav").find("ul li a[href^='#']").on('click', function (e) {
        e.preventDefault();

        var hash = this.hash;

        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 1000, function () {
            window.location.hash = hash;
        });

    });

    $('.open-link').on('click', function() {
       window.location = $(this).data('src');
    });
});

var modal = (function () {
    return {
        close: function (data) {
            if (typeof data.success !== 'undefined') {
                $('#modalBuy').modal('hide');
                $('#modalOrdered').modal('show');
            }
        }
    };
})();
